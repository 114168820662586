<template>
  <div :style="{ width: width, height: height }" class="login-box">
    <vueWxlogin :appid="appid" :scope="scope" :redirect_uri="redirect_uri"></vueWxlogin>
  </div>
</template>

<script>
/* eslint-disable */
import vueWxlogin from "./components/wxLogin/vueWxlogin.vue";
import { wxGet } from "./tools/service";
import { userstore } from "@/store/userinfo";

const status = "dev"; // 当前是开发模式还是打包模式，开发是dev,开发是build
const baseUrl = status == "dev" ? "/api" : "https://www.duoyinchina.com";

export default {
  name: "registerLogin",
  props: {
    width: {
      type: String,
      default: "100vw",
    },
    height: {
      type: String,
      default: "100vh",
    },
  },
  components: {
    vueWxlogin,
  },
  data() {
    return {
      appid: "wxc6ad6ad40d66fa8f", // 认证的小程序ID
      scope: "snsapi_login",  // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login
      redirect_uri: // 扫码后带着参数回调到哪个网页
        status == "dev"
          ? "https://www.duoyinchina.com/web/wxScaningQrcodeDev"
          : "https://www.duoyinchina.com/web/wxScaningQrcode",
      code: null,
    };
  },
  async mounted() {
    // 扫码成功回调之后的代码

    try {
      await this.getWxLogin();
    } catch (error) {
      this.$router.push("/index");
    }
  },
  methods: {
    async getWxLogin() {
      let code = this.$route.query.code; // 通过服务器重新跳转到这个页面，

      if (code) { 
        const accessInfo = await wxGet(
          baseUrl + "/web/wxLogin",
          "code=" + code
        ); // 进行登录，请求用户信息和token

        if (this.checkAccessInfo(accessInfo)) { // 返回用户信息成功

          // 可以进行修改，如何处理返回的用户信息和token


          localStorage.setItem("duoyintoken", accessInfo.token); // 进行本地存储
          localStorage.setItem(
            "duoyinuserinfo",
            JSON.stringify(accessInfo.userinfo)
          );

          userstore().updateUserInfo(); // 更新store中的userinfo

          if (path == "/index/register") {
            this.$router.push("/index");
          } else {
            this.$router.go(-2);
          }
        }else{
          console.log('通过code向服务端端申请用户信息失败')
        }
      }
    },
    checkAccessInfo(data) {
      // 验证 token 是否为字符串
      if (typeof data.token !== "string") {
        return false;
      }

      // 验证 userinfo 是否为对象
      if (typeof data.userinfo !== "object" || data.userinfo === null) {
        return false;
      }

      // 验证 user_id 是否为字符串
      if (typeof data.userinfo.user_id !== "string") {
        return false;
      }

      // 验证 headimgurl 是否为字符串
      if (typeof data.userinfo.headimgurl !== "string") {
        return false;
      }

      // 验证 register_time 是否为有效的日期字符串
      if (
        !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(
          data.userinfo.register_time
        )
      ) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="less">
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // background-color: var(--background-color);
}
</style>
